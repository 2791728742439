import React from "react";
import '../styles/Global.css';
import casa1 from '../assects/images/sala.jpeg'
import casa2 from '../assects/images/cocina.jpeg'
import casa3 from '../assects/images/dormitorio.jpeg'
import casa4 from '../assects/images/bano.jpeg'

function Modelos({eleccion}) {
  if(eleccion==='casa 1'){
    return(
      <div className="container">
        <div className="row">
          {/* <div className="col-md-6 col-sm-12">
            <span>Casa 1</span>
            <p>Descripción de la casa</p>
            <p>Esta es una casa independiente que se encuentra en su propio terreno. Una de sus principales ventajas es que ofrece mucha privacidad y libertad para personalizarla y adaptarla a sus necesidades. Además, si le gusta el espacio y la tranquilidad, esta casa es para usted. Usted tendrá todo el espacio que necesite para su familia, sus mascotas y cualquier hobby que tenga.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={casa1} className="img-fluid imagen" alt="Imagen 2" />
          </div> */}
          <div className="col-md-12 col-sm-12">
            <img src={casa1} className="img-fluid imagen" alt="Imagen 2" />
          </div>
        </div>
      </div>
    )
  }
  else if(eleccion==='casa 2'){
    return(
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <img src={casa2} className="img-fluid imagen" alt="Imagen 2" />
          </div>
          {/* <div className="col-md-6 col-sm-12">
            <img src={casa2} className="img-fluid imagen" alt="Imagen 2" />
          </div>
          <div className="col-md-6 col-sm-12">
            <span>Casa 2</span>
            <p>Descripción de la casa</p>
            <p>Los apartamentos son ideales para aquellos que buscan una vivienda más pequeña y fácil de mantener. Una de sus principales ventajas es que se encuentran en edificios, lo que significa que a menudo ofrecen seguridad y comodidades como piscina, gimnasio y áreas verdes. Además, si le gusta estar cerca de la ciudad y de las comodidades, un apartamento es una gran opción.</p>
          </div> */}
        </div>
      </div>
    )
  }
  else if(eleccion==='casa 3'){
    return(
      <div className="container">
        <div className="row">
          {/* <div className="col-md-6 col-sm-12">
            <span>Casa 3</span>
            <p>Descripción de la casa</p>
            <p>Estas casas están unidas a otras casas, lo que significa que comparten paredes con los vecinos. Una de las principales ventajas de una casa adosada es que son más económicas que una casa unifamiliar independiente y ofrecen un poco más de privacidad y espacio que un apartamento. Además, si le gusta la sensación de comunidad, las casas adosadas pueden ser perfectas para usted.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={casa3} className="img-fluid imagen" alt="Imagen 2" />
          </div> */}
          <div className="col-md-12 col-sm-12">
            <img src={casa3} className="img-fluid imagen" alt="Imagen 2" />
          </div>
        </div>
      </div>
    )
  }
  else if(eleccion==='casa 4'){
    return(
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <img src={casa4} className="img-fluid imagen" alt="Imagen 2" />
          </div>
          {/* <div className="col-md-6 col-sm-12">
            <img src={casa4} className="img-fluid imagen" alt="Imagen 2" />
          </div>
          <div className="col-md-6 col-sm-12">
            <span>Casa 4</span>
            <p>Descripción de la casa</p>
            <p>Las casas prefabricadas son casas que se construyen en una fábrica y se trasladan al lugar donde se ubicará la casa. Una de sus principales ventajas es que son más económicas y más rápidas de construir que las casas tradicionales. Además, si le gusta la idea de personalizar su hogar a su gusto, las casas prefabricadas pueden ser perfectas para usted, ya que vienen en una variedad de diseños y estilos.</p>
          </div> */}
        </div>
      </div>
    )
  }
  else {
    return null
  }

}

export default Modelos;