import React from 'react';
import '../styles/Global.css';

const Ubicacion = () => {
	return (
		<>
      <div className="jumbotron jumbotron-fluid bg-modelo" >
          <div className="container text-center py-5">
              <h3 className="text-white display-3 mb-4">Ubicación</h3>
              <div className="d-inline-flex align-items-center text-white">
                  <p className="m-2"><a className="text-white" href="">Inicio</a></p>
                  <i class="bi bi-house-gear"></i>
                  <p className="m-2">Ubicación</p>
              </div>
          </div>
      </div>

      <div className="row mx-0 justify-content-center text-center">
              <div className="col-lg-6">
                  <h6 className="d-inline-block bg-light text-primary text-uppercase py-1 px-2">la mejor ubicación</h6>
                  <h1>Encuentranos en la Mejor zona de Ica</h1>
                  <br className='ocultoMovil' />
              </div>
          </div>
      <div className="map-container mapa">
      <iframe  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25920.12718554217!2d-75.7741155!3d-14.0843182!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9110e36222557051%3A0xbab84f3350c6a35d!2sRes.CONDOMINIO%20CASALAGUNA!5e1!3m2!1ses-419!2spe!4v1694032178045!5m2!1ses-419!2spe" width="1200" height="450"  loading="lazy"></iframe>
      </div>
      
		</>
	);
}

export default Ubicacion;