import React from "react";
import '../styles/Global.css';

function Footer() {
  
  return(
    <React.Fragment>
      <div className="footer container-fluid position-relative bg-dark py-3">
        <div className="container pt-1">
            <div className="row">
                <div className="col-lg-6 pr-lg-5 mb-2">
                    <a href="index.html" className="navbar-brand">
                        <h1 className="mb-3 text-white"><span className="text-primary">P</span>SOTO</h1>
                    </a>
                    <p>Empresa de confianza con experiencia en el rubro de la construcción.</p>
                    <a href="https://psoto.com.pe/libro_reclamaciones" rel="noreferrer" target="_blank">
                    <div class="libroReclamaciones">
                        <div className="libroImagen">
                        <img className="logoLibro" src="https://borrar.psoto.com.pe/imagenes/libro-reclamos.png" title="Libro de Reclamaciones" alt="libro de reclamaciones" />
                        </div>
                        <p class="empresa-slogan">Libro de reclamaciones</p>
        
                    </div>
                    </a>
                </div>
                <div className="col-lg-6 pl-lg-5">
                    <p><i className="fa fa-map-marker-alt mr-2"></i>Urb. Valle Hermoso Mz. H lote 9</p>
                    <p><i className="fa fa-phone-alt mr-2"></i>+51 989 816 999</p>
                    <p><i className="fa fa-envelope mr-2"></i>informes@psoto.com.pe</p>
                    <p><i className="fa fa-file-pdf mr-2"></i><a target="_blank" rel="noreferrer" href="https://docs.psoto.com.pe/TyC_LoMejorParaLaMejor.pdf">Promoción "<u>Lo mejor para la mejor</u>"</a></p>
                    <div className="d-flex justify-content-start mt-4">
                        <a target="_blank" rel="noreferrer" className="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.facebook.com/profile.php?id=100090348017469&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                        <a target="_blank" rel="noreferrer" className="btn btn-lg btn-primary btn-lg-square mr-2" href="https://www.tiktok.com/@casalagunacondominio"><i className="fab fa-tiktok"></i></a>
                        <a target="_blank" rel="noreferrer" className="btn btn-lg btn-primary btn-lg-square" href="https://www.youtube.com/@PSotoInmobiliaria/featured"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </React.Fragment>
  )
}

export default Footer