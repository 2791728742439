import './App.css';
import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import AppContext from './context/AppContext';
import useInitialState from './hooks/useInitialState'
import Layout from './container/Layout';
import Home from './pages/Home'
import About from './pages/About';
import Models from './pages/Models';
import Ubicacion from './pages/Ubicacion';
import Contacto from './pages/Contacto';


function App() {
	const initialState=useInitialState();
  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/models" element={<Models />} />
            <Route path="/ubicacion" element={<Ubicacion />} />
            <Route path="/contacto" element={<Contacto />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
