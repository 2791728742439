import React from "react";
import { NavLink } from "react-router-dom";
import '../styles/Global.css';
import logo_completo from '../assects/images/logo_completo.png'
import logo_movil from '../assects/images/logo_movil.png'


function Header() {
  const [collapse, setCollapse] = React.useState(false);
  
  const colapsar=()=>{
    setCollapse(false);
  }
  
  return(
    <React.Fragment>
      <div className="container-fluid bg-light d-none d-lg-block">
          <div className="row py-2 px-lg-5">
              <div className="col-lg-6 text-left mb-2 mb-lg-0">
                  <div className="d-inline-flex align-items-center">
                      <small><i className="fa fa-phone-alt mr-2"></i>989816999</small>
                      <small className="px-3">|</small>
                      <small><i className="fa fa-envelope mr-2"></i>informes@psoto.com.pe</small>
                  </div>
              </div>
              <div className="col-lg-6 text-right">
                  <div className="d-inline-flex align-items-center">
                      <a target="_blank" className="text-primary px-2" href="https://www.facebook.com/profile.php?id=100090348017469&mibextid=ZbWKwL" >
                          <i className="fab fa-facebook-f"></i>
                      </a>
                      <a target="_blank" className="text-primary px-2" href="https://www.tiktok.com/@casalagunacondominio">
                          <i className="fab fa-tiktok"></i>
                      </a>
                      <a target="_blank" className="text-primary px-2" href="https://www.youtube.com/@PSotoInmobiliaria/featured">
                          <i className="fab fa-youtube"></i>
                      </a>
                  </div>
              </div>
          </div>
      </div>
      

      <div className="container-fluid p-0">
          <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5">
              {/* <a href="/" className="navbar-brand ml-lg-3">
                  <h1 className="m-0 text-primary"><span className="text-dark">P</span>SOTO</h1>
              </a> */}
              <picture class="header-logo">
                {/* <source media="(max-width: 480px)" srcset={logo_completo} />
                <source media="(max-width: 768px)" srcset={logo_movil} /> */}
                <img src={logo_completo} alt=""></img>
              </picture>
                
              <button onClick={()=>setCollapse(!collapse)} type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-toggler">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse justify-content-between px-lg-3 
              ${collapse ? "collapse show": null}`} id="navbar-toggler">
                  <div className="navbar-nav m-auto py-0">
                    <NavLink to={"/"}
                        onClick={colapsar}
                        className="nav-item nav-link">
                            Inicio
                        </NavLink>
                    <NavLink to={"/about"}
                        onClick={colapsar}
                        className="nav-item nav-link">
                            Nosotros
                        </NavLink>
                    <NavLink to={"/models"}
                        onClick={colapsar}
                        className="nav-item nav-link">
                            Proyecto
                        </NavLink>
                    <NavLink to={"/ubicacion"}
                        onClick={colapsar}
                        className="nav-item nav-link">
                            Ubicacion
                        </NavLink>
                    <NavLink to={"/contacto"}
                        onClick={colapsar}
                        className="nav-item nav-link">
                            Contacto
                        </NavLink>
                  </div>
                  {/* <a href="" className="btn btn-primary d-none d-lg-block">Book Now</a> */}
              </div>
          </nav>
      </div>

      
    </React.Fragment>
  )
}

export default Header