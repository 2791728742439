import React from 'react';
import casa1 from '../assects/images/sala.jpeg'
import casa2 from '../assects/images/cocina.jpeg'
import casa3 from '../assects/images/dormitorio.jpeg'
import casa4 from '../assects/images/bano.jpeg'
import modeloCasa from '../assects/images/modelocasa.jpeg'
import Modelos from '../components/Modelos';

const Models = () => {

  const [modelo, setModelo]=React.useState("casa 1");
  function ModificarModelo(alt) {
    setModelo(alt)
  }

	return (
		<>
      <div className="jumbotron jumbotron-fluid bg-modelo" >
          <div className="container text-center py-5">
              <h3 className="text-white display-3 mb-4">Proyecto</h3>
              <div className="d-inline-flex align-items-center text-white">
                  <p className="m-2"><a className="text-white" href="">Inicio </a></p>
                  <i class="bi bi-house-gear"></i>
                  <p className="m-2">Proyecto</p>
              </div>
          </div>
      </div>
      
      <div className="container-fluid px-0 py-15 my-5">
          <div className="row mx-0 justify-content-center text-center">
              <div className="col-lg-6">
                  <h6 className="d-inline-block bg-light text-primary text-uppercase py-1 px-2">Nuestro Proyecto</h6>
                  <h1>CONDOMINIO CASALAGUNA</h1>
              </div>
          </div>

          {/* Agregar imagen y descripción */}
          <div className='container-fluid p-4'>
            <div className="row">
              <div className='col-10 mx-auto'>
                <img src={modeloCasa} className='img-fluid imagen' alt="Modelo" />
              </div>
            </div>
            <div className="row">
              <div className='col-1'></div>
              <div className='col-md-5 col-sm-10'>
                <p>
                  Residencial “Condominio Casalaguna” se desarrolla sobre un área de 10,228.80 m2, en el cual se construirán 41 viviendas unifamiliares, un parque recreativo con una laguna artificial, un pórtico de ingreso, pistas y veredas, redes eléctricas de media y baja tensión, con redes de alcantarillado y agua potable.
                  Las viviendas unifamiliares estarán distribuidas en las manzanas A y B, conectadas por vias internas de pistas, pasajes y veredas. Las viviendas de la manzana B tendrán doble, una de ellas hacia el parque interior con la finalidad de establecer visuales hacia los jardines y laguna del condominio.
                </p>
              </div>
              <div className='col-md-5 col-sm-10'>
                Todas las viviendas tendrán una distribución típica, que estará compuesta por los siguientes ambientes:
                  <ul>
                    <li>Estacionamiento</li>
                    <li>Jardín</li>
                    <li>Sala</li>
                    <li>Comedor</li>
                    <li>Kitchenette</li>
                    <li>Dormitorio secundario</li>
                    <li>Dormitorio principal</li>
                    <li>Baño completo</li>
                    <li>Terraza interior</li>
                    <li>Lavandería</li>
                  </ul>
              </div>
              <div className='col-1'></div>
            </div>
          </div>

          <div className="row mx-0 justify-content-center text-center">
              <div className="col-lg-6">
                  <h6 className="d-inline-block bg-light text-primary text-uppercase py-1 px-2">Galeria</h6>
                  <p className='letra-pequeña'>*Las imagenes mostradas son referenciales.</p>
              </div>
          </div>
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-3 ">
                <img src={casa1} className="img-fluid imagen img-agrandar" alt="casa 1" 
                onClick={()=>ModificarModelo("casa 1")} />
              </div>
              <div className="col-3 ">
                <img src={casa2} className="img-fluid imagen img-agrandar" alt="casa 2" 
                onClick={()=>ModificarModelo("casa 2")} />
              </div>
              <div className="col-3 ">
                <img src={casa3} className="img-fluid imagen img-agrandar" alt="casa 3" 
                onClick={()=>ModificarModelo("casa 3")} />
              </div>
              <div className="col-3 ">
                <img src={casa4} className="img-fluid imagen img-agrandar" alt="casa 4" 
                onClick={()=>ModificarModelo("casa 4")} />
              </div>
            </div>
          </div>
      </div>
      <Modelos eleccion={modelo}></Modelos>
      
		</>
	);
}

export default Models;