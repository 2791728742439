import React from "react";


function useInitialState() {
  const [state, setState]=React.useState(null)
  const [priceState, setPriceState]=React.useState(false)
  const [serviceState, setServiceState]=React.useState(false)
  const [aboutState, setAboutState]=React.useState(false)

  function handleChangePage(value) {
    if(value.current.textContent==="about"){
        setAboutState(true)
        console.log("abouse")
        return aboutState
    }
    if(value.current.textContent==="Services"){
        setServiceState(true)
        return serviceState
    }
    if(value.current.textContent==="Pricing"){
        setPriceState(true)
        return priceState
    }
    return null
  }

  const addToCart=(payload)=>{
    const searchObject = state.cart.find(product=>{
      return product.id === payload.id
    })
    if(!searchObject){
      setState({
        ...state,
        cart:[...state.cart, payload]
      })
    }
    else{console.log("Objecto repetido, id: "+ payload.id)}
  }

  function deleteItem(payload) {
    
    setState({
      ...state,
      cart:state.cart.filter(item=>(item.id!==payload.id))
    })
  }

  return{
    state,
    addToCart,
    deleteItem,
    handleChangePage,
    priceState,
    serviceState,
    aboutState
  }
}

export default useInitialState;

