import React from 'react';
import Models from './Models'
import About from './About'
import Ubicacion from './Ubicacion'

const Home = () => {
	return (
		<>
		<Models></Models>
		<p></p>
		<About></About>
		<p></p>
		<Ubicacion></Ubicacion>
		</>
	);
}

export default Home;