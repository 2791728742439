import React from 'react';
import aboutImage from '../assects/images/laguna.jpeg'

const About = () => {
	return (
		<>
      <div className="jumbotron jumbotron-fluid bg-modelo" >
          <div className="container text-center py-5">
              <h3 className="text-white display-3 mb-4">Nosotros</h3>
              <div className="d-inline-flex align-items-center text-white">
                  <p className="m-2"><a className="text-white" href="">Inicio</a></p>
                  <i class="bi bi-house-gear"></i>
                  <p className="m-2">Nosotros</p>
              </div>
          </div>
      </div>

      
      <div className="container-fluid py">
          <div className="container py">
              <div className="row align-items-center">
                  <div className="col-lg-6 pb-5 pb-lg-0">
                      <img className="img-fluid w-100" src={aboutImage} alt="" />
                  </div>
                  <div className="col-lg-6">
                    <h6 className="d-inline-block text-primary text-uppercase bg-light py-1 px-2">Conócenos</h6>
                      <h3 className="mb-4">Historia</h3>
                      <p className="pl-4 border-left border-primary">P´Soto Constructora e Inmobiliaria SAC, nace con la finalidad de desarrollar proyectos inmobiliarios modernos, y poner en práctica los conocimientos adquiridos por nuestro staff profesional a lo largo de 20 años de experiencia en el sector vivienda y urbanismo.
                        Nuestro staff de profesionales ha participado de proyectos de vivienda emblemáticos en la ciudad de Ica, como son Urb. Las Palmeras, Urb. Residencial Los Eucaliptos, Urb. Los Huarangos.
                        </p>
                      {/* <div className="row pt-3">
                          <div className="col-6">
                              <div className="bg-light text-center p-4">
                                  <h3 className="display-4 text-primary" data-toggle="counter-up">99</h3>
                                  <h6 className="text-uppercase">Spa Specialist</h6>
                              </div>
                          </div>
                          <div className="col-6">
                              <div className="bg-light text-center p-4">
                                  <h3 className="display-4 text-primary" data-toggle="counter-up">999</h3>
                                  <h6 className="text-uppercase">Happy Clients</h6>
                              </div>
                          </div>
                      </div> */}
                  </div>
              </div>
          </div>
      </div>

      <div className="container-fluid py-2">
          <div className="container py-2">
              <div className="row align-items-center">
              <div className="col-lg-6">
                      <h6 className="d-inline-block text-primary text-uppercase bg-light py-1 px-2">Conócenos</h6>
                      <h3 className="mb-4">Quienes somos</h3>
                        <p className="pl-4 border-left border-primary">Somos una empresa Constructora e Inmobiliaria, que desarrolla sus actividades en las áreas de planificación y construcción de  proyectos inmobiliarios, cuyo fin principal es ofrecer viviendas con altos estándares de calidad que permitan lograr mayor satisfacción y  bienestar a las familias.
                        Aspiramos a er reconocidos como la mejor empresa constructora e inmobiliaria en Ica y consolidarnos en el mercado inmobiliario como una empresa innovadora, con cumplimiento y responsabilidad social
                        </p>
                  </div>
                  <div className="col-lg-6 pb-5 pb-lg-0">
                      <img className="img-fluid w-100" src={aboutImage} alt="" />
                  </div>
                  
              </div>
          </div>
      </div>
		</>
	);
}

export default About;