import React from "react";
import { useState } from "react";
import '../styles/Global.css';

function Contacto() {
    const [nombre, setNombre] = useState("");
    const [celular, setCelular] = useState("");
    const [email, setEmail] = useState("");
    const [mensaje, setMensaje] = useState("");
  
    // Función para manejar los cambios en los campos de entrada
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      // Actualizar el estado correspondiente según el nombre del campo
      if (name === "nombre") {
        setNombre(value);
      } else if (name === "celular") {
        setCelular(value);
      } else if (name === "email") {
        setEmail(value);
      } else if (name === "mensaje") {
        setMensaje(value);
      }
    };
  
    // Función para manejar el envío del formulario
    const handleSubmit = (event) => {
      event.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
  
      // Crear un objeto con los datos del formulario
      const formData = {
        nombre,
        celular,
        email,
        mensaje,
      };
  
      // Enviar los datos al backend en formato JSON
      fetch("https://www.mail.psoto.com.pe/envioCorreos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      .then((response) => {
        if (response.status === 200) {
          // Código 200 significa que la respuesta fue exitosa
          return response.json();
        } else {
          // Manejar casos de error, por ejemplo, mostrar un mensaje de error
          throw new Error("Error en la respuesta del servidor");
        }
      })
      .then((data) => {
        // Aquí puedes manejar los datos de respuesta si es necesario
        // Por ejemplo, mostrar un alert si todo está bien
        alert("El mensaje fue enviado con éxito, gracias");
  
        // Limpiar los campos del formulario
        setNombre("");
        setCelular("");
        setEmail("");
        setMensaje("");
      })
      .catch((error) => {
        console.error("Error al enviar el formulario:", error);
      });
    };


  return(
    <React.Fragment>
      <div className="container-fluid py">
        <div className="container py">
            <div className="row">
                <div className="col-lg-6 mapa-ocultar">
                    <div className="position-relative h-100">
                      <iframe  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d15478.350168873081!2d-75.74877740000001!3d-14.10150855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1ses-419!2spe!4v1694033595883!5m2!1ses-419!2spe" width="650" height="800"  loading="lazy"></iframe>
                    </div>
                </div>
                <div className="col-lg-6 pt-5 pb-lg-5 py-5">
                    <div className="contact-form bg-light p-4 p-lg-5 my-lg-5">
                        <h6 className="d-inline-block text-white text-uppercase bg-primary py-1 px-2">Contacto</h6>
                        <h1 className="mb-4">Formulario de contacto</h1>
                        <div id="success"></div>
                        <form name="sentMessage" id="contactForm" onSubmit={handleSubmit}>
                            <div className="form-row">
                            <div className="col-sm-6 control-group">
                                <input
                                type="text"
                                className="form-control border-0 p-4"
                                id="name"
                                name="nombre"
                                placeholder="Nombre"
                                required="required"
                                data-validation-required-message="Por favor ingrese su nombre"
                                value={nombre}
                                onChange={handleInputChange}
                                />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="col-sm-6 control-group">
                                <input
                                type="text"
                                className="form-control border-0 p-4"
                                id="celular"
                                name="celular"
                                placeholder="Celular"
                                required="required"
                                data-validation-required-message="Por favor ingrese su celular"
                                value={celular}
                                onChange={handleInputChange}
                                />
                                <p className="help-block text-danger"></p>
                            </div>
                            </div>
                            <div className="control-group">
                            <input
                                type="email"
                                className="form-control border-0 p-4"
                                id="subject"
                                name="email"
                                placeholder="Email"
                                required="required"
                                data-validation-required-message="Por favor ingrese su email"
                                value={email}
                                onChange={handleInputChange}
                            />
                            <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                            <textarea
                                className="form-control border-0 py-3 px-4"
                                rows="3"
                                id="message"
                                name="mensaje"
                                placeholder="Quisiera mayor información..."
                                required="required"
                                data-validation-required-message="Quisiera mayor información..."
                                value={mensaje}
                                onChange={handleInputChange}
                            ></textarea>
                            <p className="help-block text-danger"></p>
                            </div>
                            <div>
                            <button
                                className="btn btn-primary py-3 px-4"
                                type="submit"
                                id="sendMessageButton"
                            >
                                Solicitar
                            </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

      
      
    </React.Fragment>
    
  )
}

export default Contacto;